import React from 'react';
import { graphql } from 'gatsby';

import Hero from '../components/Hero';
import Layout from '../components/Layout/Layout';
import { Container } from '../components/Grid';
import Band from '../components/Band/Band';
import Contact from '../components/Contact/Contact';
import PageHeader from '../components/PageHeader/PageHeader';
import Typography, { Highlight } from '../components/Typography';
import SEO from '../components/SEO';
import Tiles from '../components/Tiles/Tiles';

export default ({ data }) => (
    <Layout>
        <SEO
            title="Recruiting candidates for impact"
            description="Black Dot deliver candidates that enhance and have a lasting impact in organisations. We use our creativity to build long-term relationships with our clients, developing a genuine understanding of their needs"
        />
        <Hero
            title="Who are Black Dot?"
            mobileImage={data.mobileImage}
            desktopImage={data.desktopImage}
            logoPosition="top-right"
        />
        <Band size="md">
            <Container>
                <Typography color="gold">
                    <PageHeader>
                        We aim to deliver candidates that enhance and have a lasting impact in organisations
                    </PageHeader>
                    <p>
                        Our experience spans across a broad range of industries which means we understand evolving
                        challenges and can quickly recognise an emerging opportunity.
                    </p>
                    <p>
                        We use our creativity to build long-term relationships with our clients, developing a genuine
                        understanding of your needs and what skills & experience you might need to fulfil your future
                        challenges.
                    </p>
                    <p>
                        <Highlight>
                            We achieve success when we match ambitions, values, talent and opportunities.
                        </Highlight>
                    </p>
                </Typography>
            </Container>
        </Band>
        <Band>
            <Tiles />
        </Band>
        <Band size="md">
            <Container>
                <Contact heading="Let's connect" />
            </Container>
        </Band>
    </Layout>
);

export const query = graphql`
    query WhoPageQuery {
        desktopImage: file(relativePath: { eq: "unsplash-CMOa3H1SXG0.jpg" }) {
            ...HeroBackgroundImage_Desktop
        }
        mobileImage: file(relativePath: { eq: "unsplash-sm-CMOa3H1SXG0.jpg" }) {
            ...HeroBackgroundImage_Mobile
        }
    }
`;
